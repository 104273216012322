<template>
  <div class="game-container layout-border">
    <ClickTrainGame
      :strokeHanzi="strokeHanzi"
      :gameIndex="gameIndex"
      :hanziStrokeInfo="hanziStrokeInfo"
      :lightIndex="lightIndex"
      :shadowImg = "shadowImg"
      :headerBgImg = "headerBgImg"
      :strokePinyin="strokePinyin"
    />
  </div>
</template>

<script>
import ClickTrainGame from "@/components/Course/GamePage/ClickTrainGame";
export default {
  data() {
    return {
      headerBgImg:{
        defaultImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/0-bulb-on.svg"),
        firstImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/1-bulb-on.svg"),
        secondImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/2-bulb-on.svg"),
        thirdImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/3-bulb-on.svg"),
      },
      shadowImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"),
      strokeHanzi: "中国早饭",
      strokePinyin: "Zhōngguó zǎofàn",
      gameIndex: 1,
      lightIndex: [1, 2, 3],
      hanziStrokeInfo: [
        {
          strokeBgImg: require("@/assets/img/16-GAMES/G69-culture-train/l2-lesson-02/1-baozi.svg"),
        },
        {
          strokeBgImg: require("@/assets/img/16-GAMES/G69-culture-train/l2-lesson-02/2-jiaozi.svg"),
        },
        {
          strokeBgImg: require("@/assets/img/16-GAMES/G69-culture-train/l2-lesson-02/3-kuaizi.svg"),
        },
      ],
    };
  },
  components: {
    ClickTrainGame,
  },
};
</script>

<style lang="scss" scoped>
</style>