<template>
  <div class="game-container">
    <RaceGame :answerList="answerList" :elementOptionList="elementOptionList" />
  </div>
</template>

<script>
import RaceGame from "@/components/Course/GamePage/RaceGame";
export default {
  data() {
    return {
      answerList: [
        {
          id: 1,
          pinying: "bāozi",
          hanzi: "包子",
          data: "4",
        },
        {
          id: 2,
          pinying: "jiǎozi",
          hanzi: "饺子",
          data: "10",
        },
        {
          id: 3,
          pinying: "kuàizi",
          hanzi: "筷子",
          data: "1",
        },
        {
          id: 4,
          pinying: "miàntiáor",
          hanzi: "面条儿",
          data: "7",
        },
        {
          id: 5,
          pinying: "yóutiáo",
          hanzi: "油条",
          data: "9",
        },
        {
          id: 6,
          pinying: "dòujiāng",
          hanzi: "豆浆",
          data: "5",
        },
      ],
      elementOptionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G40-race/1.svg"),
          pinying: "kuàizi",
          hanzi: "筷子",
          canPass: false,
          data: "1",
          isVisible: true,
        },
        {
          id: 2,
          image: "",
          pinying: "èr",
          hanzi: "二",
          canPass: true,
          data: "2",
          isVisible: true,
        },
        {
          id: 3,
          image: "",
          pinying: "sān",
          hanzi: "三",
          canPass: true,
          data: "3",
          isVisible: true,
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G40-race/4.svg"),
          pinying: "bāozi",
          hanzi: "包子",
          canPass: false,
          data: "4",
          isVisible: true,
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G40-race/5.svg"),
          pinying: "dòujiāng",
          hanzi: "豆浆",
          canPass: false,
          data: "5",
          isVisible: true,
        },
        {
          id: 6,
          image: "",
          pinying: "liù",
          hanzi: "六",
          canPass: true,
          data: "6",
          isVisible: true,
        },
        {
          id: 7,
          image: require("@/assets/img/16-GAMES/G40-race/7.svg"),
          pinying: "miàntiáor",
          hanzi: "面条儿",
          canPass: false,
          data: "7",
          isVisible: true,
        },
        {
          id: 8,
          image: "",
          pinying: "bā",
          hanzi: "八",
          canPass: true,
          data: "8",
          isVisible: true,
        },
        {
          id: 9,
          image: require("@/assets/img/16-GAMES/G40-race/9.svg"),
          pinying: "yóutiáo",
          hanzi: "油条",
          canPass: false,
          data: "9",
          isVisible: true,
        },
        {
          id: 10,
          image: require("@/assets/img/16-GAMES/G40-race/10.svg"),
          pinying: "jiǎozi",
          hanzi: "饺子",
          canPass: false,
          data: "10",
          isVisible: true,
        },
      ],
    };
  },
  components: {
    RaceGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











