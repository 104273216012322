<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage :singList="singList" :songInfo="songInfo" :timeStampList="timeStampList" :isReviewPage="isReviewPage"></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-71.wav"),
      },
      timeStampList: [17.8, 22.5, 29.3, 35.3, 43.3],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Bāozi,bāozi,jiǎozi,jiǎozi,",
            chineseWords: "包子、包子，饺子、饺子，",
          },
          {
            id: 2,
            spelling: "Wǒ xǐhuan chī bāozi.Tā xǐhuan chī jiǎozi.",
            chineseWords: "我喜欢吃包子。他喜欢吃饺子。",
          },
          {
            id: 3,
            spelling: "Dòujiāng yóutiáo, miàntiáor,miàntiáor,",
            chineseWords: "豆浆，油条，面条儿、面条儿，。",
          },
          {
            id: 4,
            spelling: "Chī yóutiáo,hē dòujiāng.Shéi xǐhuan chī miàntiáor？",
            chineseWords: "吃油条，喝豆浆。谁喜欢吃面条儿？",
          },
          {
            id: 5,
            spelling: "Bāozi,jiǎozi,dòujiāng,yóutiáo hé miàntiáor.",
            chineseWords: "包子、饺子、豆浆、油条和面条儿。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
