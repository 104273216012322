<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "bāo zi",
              hanzi: "包子",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "jiǎo zi",
              hanzi: "饺子",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "kuài zi",
              hanzi: "筷子",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "miàntiáor",
              hanzi: "面条儿",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "yóu tiáo",
              hanzi: "油条",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "dòu jiāng",
              hanzi: "豆浆",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },

            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我知道",
          isUse: true,
          contentList: [
            
              // {
              //   pinyin: "nǐ",
              //   hanzi: "你",
              //   symbol: "",
              //   id: 1,
              //   symbolType: 0,
              // },

              // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”

             
            [
              {
                pinyin: "\xa0",
                hanzi: "1",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: ".",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
             
              {
                pinyin: "rèn shí cháng jiàn de zhōng guó zǎo fàn hé cān jù",
                hanzi: "认识常见的中国早饭和餐具",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
             
            ],
            [
              {
                pinyin: "\xa0",
                hanzi: "2",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: ".",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
             
              {
                pinyin: "liǎo jiě zhè xiē zǎo fàn de cháng jiàn chī fǎ",
                hanzi: "了解这些早饭的常见吃法",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
             
            ],
          ],
        },
        
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>