var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container layout-border" },
    [
      _c("ClickTrainGame", {
        attrs: {
          strokeHanzi: _vm.strokeHanzi,
          gameIndex: _vm.gameIndex,
          hanziStrokeInfo: _vm.hanziStrokeInfo,
          lightIndex: _vm.lightIndex,
          shadowImg: _vm.shadowImg,
          headerBgImg: _vm.headerBgImg,
          strokePinyin: _vm.strokePinyin,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }