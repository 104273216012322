<template>
  <div class="game-container">
    <RecognitionPinyinGame
      :optionList="optionList"
      :imgList="imgList"
      :lessonNum="7"
    />
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      optionList: [
        {
          id: 1,
          // image: require("@/assets/img/14-Pinyin/pinyin-a-image.svg"),
        },
        {
          id: 2,
          // image: require("@/assets/img/14-Pinyin/pinyin-o-image.svg"),
        },
        {
          id: 3,
          // image: require("@/assets/img/14-Pinyin/pinyin-e-image.svg"),
        },
      ],

      imgList: [
        {
          id: 1,
          // bottomImg: require("@/assets/img/14-Pinyin/pinyin-a.svg"),
        },
        {
          id: 2,
          // bottomImg: require("@/assets/img/14-Pinyin/pinyin-o.svg"),
        },
        {
          id: 3,
          // bottomImg: require("@/assets/img/14-Pinyin/pinyin-e.svg"),
        },
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











